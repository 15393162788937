import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import GoService from '../../services/goService'
// Fonction pour obtenir le timestamp actuel en millisecondes
const getCurrentTimestamp = () => {
  const now = new Date()
  return now.getTime()
}
// Vérifier si les données sont dans le localStorage
const loadFromLocalStorage = (name) => {
  try {
    const serializedState = localStorage.getItem(name)
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    console.error('Could not load state', err)
    return err
  }
}
// Sauvegarder les données dans le localStorage
const saveToLocalStorage = (data, name) => {
  try {
    const serializedState = JSON.stringify(data)
    localStorage.setItem(name, serializedState)
  } catch (err) {
    console.error('Could not save state', err)
    return err
  }
}
export const GetActiveDisruptions = createAsyncThunk(
  'go/GetActiveDisruptions',
  async (_, thunkAPI) => {
    try {
      const timestamp = getCurrentTimestamp()
      const data = {
        Lang: 'fr',
        Datetime: `/Date(${timestamp}+0200)/`,
      }

      const response = await GoService.GetActiveDisruptions(data)

      // Transformation des données pour obtenir les LineId uniques par objet
      const transformedData = response.data.Data.map((item) => {
        const uniqueLineIds = Array.from(
          new Set(item.DisruptedLines.map((line) => line.LineId))
        )
        return {
          ...item,
          DisruptedLines: uniqueLineIds,
        }
      })
      // Transformation des données pour ne garder que les LineId uniques
      const disruptedLines = Array.from(
        new Set(
          response.data.Data.flatMap((item) =>
            item.DisruptedLines.map((line) => line.LineId)
          )
        )
      )
      // Sauvegarder les données dans le localStorage
      saveToLocalStorage(transformedData, 'disruptions')
      saveToLocalStorage(disruptedLines, 'lines')
      return { transformedData, disruptedLines }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response || 'Erreur')
    }
  }
)
export const GetLinesDetails = createAsyncThunk(
  'go/GetLinesDetails',
  async (_, thunkAPI) => {
    try {
      const timestamp = getCurrentTimestamp()
      const lines = loadFromLocalStorage('lines') || []
      const data = {
        Lang: 'fr',
        Datetime: `/Date(${timestamp}+0200)/`,
        LineIds: lines,
      }

      const response = await GoService.GetLinesDetails(data)
      return response.data.Data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response || 'Erreur')
    }
  }
)
export const getTheLineDistruptionByLineId = createAsyncThunk(
  'go/getTheLineDistruptionByLineId',
  async ({ id }, thunkAPI) => {
    try {
      const dist = loadFromLocalStorage('disruptions') || []
      const numericId = Number(id)
      const response = dist.filter((item) =>
        item.DisruptedLines.includes(numericId)
      )

      const timestamp = getCurrentTimestamp()
      const data = {
        Lang: 'fr',
        Datetime: `/Date(${timestamp}+0200)/`,
        LineIds: response[0].DisruptedLines,
      }

      const allLines = await GoService.GetLinesDetails(data)
      // Find the item with Id equal to numericId
      const theLineIndex = allLines.data.Data.findIndex(
        (item) => item.Id === numericId
      )

      // if (theLineIndex !== -1) {
      // Store the item in theLine
      const theLine = allLines.data.Data[theLineIndex]

      // Remove the item from the allLines.data.Data array
      allLines.data.Data.splice(theLineIndex, 1)
      const impactedLines = allLines.data.Data
      const finalData = {
        theLine: theLine,
        theLinesImpacted: impactedLines,
        theLineDistruption: response[0],
      }
      return finalData
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response || 'Erreur')
    }
  }
)

const goSlice = createSlice({
  name: 'go',
  initialState: {
    Go: null,
    theLineDistruption: null,
    theLinesImpacted: null,
    theLine: null,
    disruptions: loadFromLocalStorage('disruptions') || [],
    lines: loadFromLocalStorage('lines') || [],
    linesDetails: [],
    error: null,
    loading: false,
    status: 'idle',
  },
  reducers: {
    showLoading: (state) => {
      state.loading = true
    },
    hideLoading: (state, action) => {
      state.loading = false
    },
    hideError: (state, action) => {
      state.error = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetActiveDisruptions.pending, (state) => {
        state.loading = true
        state.error = null
        state.status = 'loading'
      })
      .addCase(GetActiveDisruptions.fulfilled, (state, action) => {
        state.loading = false
        state.status = 'succeeded'
        state.disruptions = action.payload.transformedData
        state.lines = action.payload.disruptedLines
      })
      .addCase(GetActiveDisruptions.rejected, (state, action) => {
        state.loading = false
        state.status = 'failed'
        state.error = action.payload
      }) //GetLinesDetails
      .addCase(GetLinesDetails.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(GetLinesDetails.fulfilled, (state, action) => {
        state.loading = false
        state.linesDetails = action.payload
      })
      .addCase(GetLinesDetails.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      }) //theLineDistruption
      .addCase(getTheLineDistruptionByLineId.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getTheLineDistruptionByLineId.fulfilled, (state, action) => {
        state.loading = false
        state.theLineDistruption = action.payload.theLineDistruption
        state.theLine = action.payload.theLine
        state.theLinesImpacted = action.payload.theLinesImpacted
      })
      .addCase(getTheLineDistruptionByLineId.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  },
})

export default goSlice.reducer
export const { showLoading, hideLoading, hideError } = goSlice.actions
