import React from 'react'
import { Collapse, Skeleton } from 'antd'
import { useNavigate } from 'react-router-dom'

const { Panel } = Collapse
function getRandomColor() {
  // Génère une couleur avec une luminosité plus basse et moins de saturation
  const luminance = Math.floor(Math.random() * 20) + 40 // Valeur entre 40 et 60
  const saturation = Math.floor(Math.random() * 50) + 50 // Valeur entre 50 et 100
  const color = `hsl(${Math.floor(
    Math.random() * 360
  )}, ${saturation}%, ${luminance}%)`
  return color
}

const buttonsLines = Array.from({ length: 29 }, (_, i) => i + 1) // Crée un tableau de nombres de 1 à 29

const RegionCards = ({ name, handleButtonClick, image, data, loading }) => (
  <div className="theCards">
    <span className="logoTitreRegion">
      {React.cloneElement(image, { style: { marginRight: '8px' } })}
      <span style={{ fontWeight: 'bold' }}>{name}</span>
    </span>
    <div className="grid-container">
      {loading
        ? Array.from({ length: 10 }).map((_, index) => (
            <div className="button-container" key={index}>
              <Skeleton active />
            </div>
          ))
        : data.slice(0, 100).map((info) => (
            <div className="button-container" key={info.Id}>
              <button
                className="region-button"
                style={{ backgroundColor: '#' + info.Color }}
                onClick={() => handleButtonClick(info.Id)}
              >
                {info.Number}
              </button>
              <span className="badge"></span>
            </div>
          ))}
    </div>
  </div>
)

const RegionAccordion = ({ region, handleButtonClick, image }) => (
  <Collapse
    // ghost
    // style={{ marginBottom: '10px' }}
    style={{ width: '100%', fontWeight: 'bold' }}
    expandIconPosition="end"
    accordion
    size="large"
  >
    <Panel header={region} key="1">
      <div className="grid-container">
        {buttonsLines.map((number) => (
          // <Badge dot="true">
          <div className="button-container">
            <button
              key={number}
              className="region-button"
              style={{ backgroundColor: getRandomColor() }}
              onClick={() => handleButtonClick(number)}
            >
              {number}
            </button>
            <span className="badge"></span>
          </div>
          // </Badge>
        ))}
      </div>
    </Panel>
  </Collapse>
)

const CardRegion = ({ name, image, isMobile, data, loading }) => {
  const navigate = useNavigate()
  const handleButtonClick = (id) => {
    navigate(`/ligne/${id}`)
  }

  return (
    <>
      {isMobile ? (
        <RegionAccordion
          region={name}
          handleButtonClick={handleButtonClick}
          image={image}
          loading={loading}
        />
      ) : (
        <RegionCards
          data={data}
          name={name}
          handleButtonClick={handleButtonClick}
          image={image}
          loading={loading}
        />
      )}
    </>
  )
}

export default CardRegion
