import { Tabs, Card } from 'antd'
import { useState, useEffect } from 'react'
import BateauIcon from '../../components/icons/BateauIcon'
import CarIcon from '../../components/icons/CarIcon'
import TrainIcon from '../../components/icons/TrainIcon'
import ArmorIcon from '../../components/icons/ArmorIcon'
import FinestreIcon from '../../components/icons/FinestreIcon'
import VilaineIcon from '../../components/icons/VilaineIcon'
import MorbihanIcon from '../../components/icons/MorbihanIcon'
import CardRegion from './CardRegion'
import { useSelector, useDispatch } from 'react-redux'
import {
  GetActiveDisruptions,
  GetLinesDetails,
} from '../../store/slice/goSlice'

const { TabPane } = Tabs

const MainContent = () => {
  const [activeTab, setActiveTab] = useState('1')
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)

  const { disruptions, status, linesDetails, loading } = useSelector(
    (store) => store.go
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (disruptions.length === 0 && status === 'idle') {
      dispatch(GetActiveDisruptions())
    }
    if (disruptions.length !== 0) {
      dispatch(GetLinesDetails())
    }
  }, [dispatch, disruptions.length, status])
  // useEffect(() => {
  //   dispatch(GetActiveDisruptions())
  //   dispatch(GetLinesDetails())
  // }, [dispatch])
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleChange = (key) => {
    setActiveTab(key)
  }

  const getTabTitle = (id) => {
    switch (id) {
      case '1':
        return (
          <span
            style={{
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              fontSize: '15px',
            }}
          >
            <CarIcon style={{ marginRight: '8px' }} />
            Car
          </span>
        )
      case '2':
        return (
          <span
            style={{
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              fontSize: '15px',
            }}
          >
            <TrainIcon style={{ marginRight: '8px' }} />
            <span>TER</span>
          </span>
        )
      case '3':
        return (
          <span
            style={{
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              fontSize: '15px',
            }}
          >
            <BateauIcon style={{ marginRight: '8px' }} />
            Bateau
          </span>
        )
      default:
        return `Tab ${id}`
    }
  }
  const regions = [
    { name: "Côtes d'Armor", image: <ArmorIcon /> },
    { name: 'Finistère', image: <FinestreIcon /> },
    { name: 'Ille-et-Vilaine', image: <VilaineIcon /> },
    { name: 'Morbihan', image: <MorbihanIcon /> },
  ]
  const tabPanes = new Array(3).fill(null).map((_, i) => {
    const id = String(i + 1)
    return (
      <TabPane tab={getTabTitle(id)} key={id}>
        <div className="cardContainer">
          {regions.map((region, index) => (
            <CardRegion
              loading={loading}
              data={linesDetails}
              key={index}
              isMobile={isMobile}
              name={region.name}
              image={region.image}
            />
          ))}
        </div>
      </TabPane>
    )
  })

  return (
    <div className="mainContent">
      <Tabs activeKey={activeTab} onChange={handleChange} type="card" centered>
        {tabPanes}
      </Tabs>
    </div>
  )
}

export default MainContent
