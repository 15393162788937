import Logo from "./Logo";
import Tools from "./Tools";
const Header = () => {
  return (
    <header className="header">
      <Logo />
      <Tools />
    </header>
  );
};

export default Header;
