import {
  SearchOutlined,
  UserOutlined,
  AlignRightOutlined,
} from '@ant-design/icons'
const Tools = () => {
  return (
    <div className="tools">
      <SearchOutlined style={{ marginRight: '10%', fontSize: '26px' }} />
      <UserOutlined style={{ marginRight: '10%', fontSize: '26px' }} />
      <AlignRightOutlined style={{ marginRight: '10%', fontSize: '26px' }} />
    </div>
  )
}

export default Tools
