import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import InfoTrafic from './views/InfoTrafic'
import Ligne from './views/Ligne'
import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import { Provider } from 'react-redux'
import { Store } from './store/store'
function App() {
  return (
    <Provider store={Store}>
      <Router>
        <Header />
        <main className="content-wrapper">
          <Routes>
            <Route path="/" element={<InfoTrafic />} />
            <Route path="/ligne/:id" element={<Ligne />} />
          </Routes>
        </main>
        <Footer />
      </Router>
    </Provider>
  )
}

export default App
