import { useState } from 'react'
import { Breadcrumb, Button, Skeleton } from 'antd'
import { EnvironmentOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
const InfoLigne = ({ theLine, loading }) => {
  const ligne = {
    numero: theLine?.Number,
    ville: theLine?.Name,
    region: 'Peland Le Grand',
  }
  const navigate = useNavigate()
  const handleBackClick = () => {
    navigate('/')
  }
  return (
    <div className="info">
      <div className="navAcceuil">
        <Breadcrumb
          separator=""
          items={[
            { href: '', title: 'Accueil' },
            {
              type: 'separator',
              separator: '>',
            },
            {
              href: '',
              title: (
                <span onClick={handleBackClick} style={{ cursor: 'pointer' }}>
                  {'Infos trafic'}
                </span>
              ),
            },
            {
              type: 'separator',
              separator: '>',
            },
            { href: '', title: 'Ligne ' + theLine?.Number },
          ]}
        />
        {/* <a href="#">Accueil</a>
        <span>&gt;</span>
        <a href="#">Infos</a> */}
      </div>
      <div className="infoTrafic">
        <Breadcrumb
          style={{ fontWeight: 'bold', color: 'black' }}
          separator=""
          items={[
            {
              href: '',
              title: (
                <span onClick={handleBackClick} style={{ cursor: 'pointer' }}>
                  {'<  Retour'}
                </span>
              ),
            },
          ]}
        />
        <h1>INFO TRAFIC</h1>
        <div className="infoTraficNomVille">
          {loading ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Skeleton.Input active />
            </div>
          ) : (
            <>
              <Button
                disabled
                size="middle"
                style={{
                  cursor: 'default',
                  backgroundColor: '#' + theLine?.Color,
                  color: 'white',
                  marginRight: '0.5%',
                }}
              >
                {theLine?.Number}
              </Button>
              <span>&gt; </span>
              <span>{theLine?.Name}</span>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default InfoLigne
