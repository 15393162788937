import { useState } from 'react'
import { Breadcrumb, Input } from 'antd'
import { EnvironmentOutlined } from '@ant-design/icons'
const Info = () => {
  const [temps, setTemps] = useState('00:00')
  const { Search } = Input
  const onSearch = (e) => {
    console.log('serach', e)
  }
  return (
    <section className="info">
      <div className="navAcceuil">
        <Breadcrumb
          separator=""
          items={[
            { href: '', title: 'Accueil' },
            {
              type: 'separator',
              separator: '>',
            },
            {
              href: '',
              title: 'Infos trafic',
            },
          ]}
        />
        {/* <a href="#">Accueil</a>
        <span>&gt;</span>
        <a href="#">Infos</a> */}
      </div>
      <div>
        <div className="infoTrafic">
          <h1>INFOS TRAFIC</h1>
          <span className="textMaj">Mis a jour a {temps}</span>
        </div>
        {/* <div className="SearchBarTrafic">
          <Search
            placeholder="un arret, une ligne"
            onSearch={onSearch}
            style={{
              width: '40vw',
            }}
            prefix={<EnvironmentOutlined />}
          />
        </div> */}
      </div>
    </section>
  )
}

export default Info
