import fetch from '../api/axios'
const GoService = {}
// GoService.getActiveDistruptions = function () {
//   return fetch({
//     method: 'get',
//     url: '/api/transport/v3/disruption/GetActiveDisruptions/json?user_key=BREIZHGO_V2_PP',
//   })
// }
GoService.GetActiveDisruptions = function (data) {
  return fetch({
    method: 'post',
    url: '/api/transport/v3/disruption/GetActiveDisruptions?user_key=BREIZHGO_V2_PP',
    data: data,
    headers: {
      // 'Content-Type': 'application/json', // Spécifie que vous envoyez des données JSON
      Accept: 'application/json', // Spécifie que vous attendez une réponse en JSON
    },
  })
}
GoService.GetLinesDetails = function (data) {
  return fetch({
    method: 'post',
    url: '/api/transport/v3/line/GetLinesDetails?user_key=BREIZHGO_V2_PP',
    data: data,
    headers: {
      // 'Content-Type': 'application/json', // Spécifie que vous envoyez des données JSON
      Accept: 'application/json', // Spécifie que vous attendez une réponse en JSON
    },
  })
}
export default GoService
