import InfoLigne from './InfoLigne'
import MainContentLigne from './MainContentLigne'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getTheLineDistruptionByLineId } from '../../store/slice/goSlice'
function Ligne() {
  let { id } = useParams()
  const { theLineDistruption, theLine, theLinesImpacted, loading } =
    useSelector((store) => store.go)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getTheLineDistruptionByLineId({ id }))
  }, [dispatch, id])
  return (
    <>
      <InfoLigne theLine={theLine} loading={loading} />
      <MainContentLigne
        loading={loading}
        theLineDistruption={theLineDistruption}
        theLinesImpacted={theLinesImpacted}
      />
    </>
  )
}

export default Ligne
