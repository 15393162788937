import { useNavigate } from 'react-router-dom'

import { Button, Skeleton } from 'antd'
import { RightOutlined, ArrowRightOutlined } from '@ant-design/icons'

function MainContentLigne({ theLineDistruption, theLinesImpacted, loading }) {
  const navigate = useNavigate()
  const handleButtonClick = (id) => {
    console.log(id)
    navigate(`/ligne/${id}`)
  }
  return (
    <div className="mainContentLigne">
      <div className="mainContentLigneInfo">
        <h2 style={{ marginBottom: '1%' }}>{theLineDistruption?.Name}</h2>
        <span style={{ fontSize: 'small' }}>
          {theLineDistruption?.Description}
        </span>
        <br></br>
        <a style={{ fontSize: 'small', color: 'blue', fontWeight: 'bold' }}>
          Consulter la fiche de la ligne <ArrowRightOutlined />
        </a>
      </div>
      <div className="mainContentLigneAutresLigne">
        <h2 style={{ marginBottom: '1%' }}>AUTRES LIGNES IMPACTEES</h2>
        <ul>
          {loading ? (
            <>
              <li>
                <Skeleton.Button active />
              </li>
              <li>
                <Skeleton.Button active />
              </li>
              <li>
                <Skeleton.Button active />
              </li>
            </>
          ) : theLinesImpacted?.length === 0 ? (
            <li>Aucune ligne impactée</li>
          ) : (
            theLinesImpacted?.map((item, index) => (
              <li key={index}>
                <Button
                  size="middle"
                  style={{
                    backgroundColor: '#' + item.Color,
                    marginRight: '0.5%',
                    color: 'white',
                  }}
                  onClick={() => handleButtonClick(item.Id)}
                >
                  {item.Number}
                </Button>
                <span style={{ marginRight: '25%', marginLeft: '5%' }}>
                  {item.Name}
                </span>
                <RightOutlined />
              </li>
            ))
          )}
        </ul>
      </div>
    </div>
  )
}

export default MainContentLigne
