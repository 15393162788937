import React from 'react'
import Icon from '@ant-design/icons/lib/components/Icon'
function BateauIcon(props) {
  const BateauSvg = () => {
    return (
      <svg
        width="22"
        height="18"
        viewBox="0 0 22 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_4773_291)">
          <path
            d="M20.9201 15.5284C21.0176 15.5749 21.1029 15.6413 21.1698 15.7226C21.2368 15.804 21.2837 15.8983 21.3071 15.9988C21.3306 16.0992 21.33 16.2034 21.3055 16.3036C21.281 16.4038 21.2331 16.4977 21.1653 16.5784C21.0975 16.6591 21.0115 16.7247 20.9135 16.7703C20.8155 16.816 20.7079 16.8406 20.5986 16.8424C20.4892 16.8441 20.3808 16.8231 20.2812 16.7807C20.1816 16.7383 20.0932 16.6756 20.0225 16.5971L17.2728 14.6092L14.2958 16.9605L10.9325 14.5879L7.60327 16.9605L4.62629 14.6092L1.83112 16.6292C1.67947 16.6965 1.508 16.7132 1.34488 16.6767C1.18176 16.6402 1.03671 16.5525 0.933586 16.4282C0.830459 16.3038 0.775391 16.1501 0.777437 15.9924C0.779482 15.8347 0.83852 15.6824 0.944841 15.5604L2.17199 14.6734C0.953071 13.5302 0.256045 11.9851 0.229004 10.3663C0.231057 9.63481 0.377462 8.91003 0.660779 8.2288L10.887 5.89893L21.1133 8.2288C21.4058 8.90787 21.5562 9.63363 21.5564 10.3663C21.5223 11.9764 20.8211 13.5104 19.6021 14.6413L20.9201 15.5284Z"
            fill="black"
          />
          <path
            d="M16.5569 1.83776V-0.000488281H5.308V1.83776H2.50146V6.41201L10.9438 4.5417L19.3634 6.41201V1.83776H16.5569ZM10.5688 3.26989H7.23963V1.77364H10.5688V3.26989ZM14.6253 3.26989H11.296V1.77364H14.6253V3.26989Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_4773_291">
            <rect
              width="21.375"
              height="18"
              fill="white"
              transform="translate(0.479004 -0.000488281)"
            />
          </clipPath>
        </defs>
      </svg>
    )
  }
  return <Icon component={BateauSvg} {...props} />
}

export default BateauIcon
