import Info from './Info'
import MainContent from './MainContent'

function InfoTrafic() {
  return (
    <>
      <Info />
      <MainContent />
    </>
  )
}

export default InfoTrafic
